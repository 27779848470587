
import FilterMenu from "~/components/rent-levels/FilterMenu.vue";

import WidgetA from "~/components/rent-levels/WidgetA.vue";
import WidgetC from "~/components/rent-levels/WidgetC.vue";
import WidgetD from "~/components/rent-levels/WidgetD.vue";
import WidgetF from "~/components/rent-levels/WidgetF.vue";
import WidgetG from "~/components/rent-levels/WidgetG.vue";
import WidgetH from "~/components/rent-levels/WidgetH.vue";
import WidgetI from "~/components/rent-levels/WidgetI.vue";
import WidgetJ from "~/components/rent-levels/WidgetJ.vue";

import Me from "~/graphql/Authentication/Me.gql";
import LocationRentalEstimate from "~/graphql/Property/LocationRentalEstimate.gql";

import {
  getTypeFromSector,
  getReferenceTotalArea,
  getReferenceConfidence,
  getReferenceSizeByType,
  getReferenceMovingInYear,
  getReferenceNumberOfUnits,
  getReferenceDistanceToUnits,
  getReferenceConstructionYear,
  getReferenceResidentialConstructions,
} from "~/helpers/rent-level-helpers.js";
import { writeSheetsToXSLXAndSave, createLocationRentLevelsSheet } from "~/helpers/download-helpers.js";

export default {
  title() {
    return this.$t("MENU_RENTAL");
  },

  components: {
    WidgetA,
    WidgetC,
    WidgetD,
    WidgetF,
    WidgetG,
    WidgetH,
    WidgetI,
    WidgetJ,
    FilterMenu,
  },

  apollo: {
    me: {
      query: Me,
    },

    locationRentalEstimate: {
      query: LocationRentalEstimate,

      variables() {
        const polygon = [];

        if (this.drawingCoordinates && this.drawingCoordinates[0]) {
          this.drawingCoordinates[0].forEach((c) => {
            polygon.push({
              lat: c[1],
              lon: c[0],
            });
          });
        }

        const r = {
          search: {
            sectorFilters: this.searchFilters.map((f) => {
              if (polygon.length > 0) {
                f.polygon = polygon;
              }
              return f;
            }),
          },
        };

        return r;
      },

      errorPolicy: "all",

      update(data) {
        if (data && data.locationRentalEstimate) {
          this.errorSearchTooLarge = false;
        }
        return data.locationRentalEstimate;
      },

      skip() {
        return !this.searchFilters || !this.drawingCoordinates;
      },

      error(error) {
        const errorMessage = error.message.replace("GraphQL error: ", "");
        if (errorMessage.indexOf("too many rental units in search result") != -1 || errorMessage.indexOf("too many properties in search result") != -1) {
          this.errorSearchTooLarge = true;
        }
      },
    },
  },

  data() {
    return {
      searchFilters: null,
      errorSearchTooLarge: false,
      sectors: ["RESIDENTIAL_FREE_RENT", "SHOP", "OFFICE", "INDUSTRY", "RESIDENTIAL_SMALL", "RESIDENTIAL_RENTAL", "RESIDENTIAL_COST"],
    };
  },

  computed: {
    loading() {
      return this.$apollo.queries.locationRentalEstimate.loading;
    },

    showNotificationMissingData() {
      return false;
    },

    showOtherBusinessBanner() {
      return false;
    },

    drawingCoordinates() {
      return this.$store.getters["areas/getCoordinates"];
    },

    showNoInputState() {
      return this.drawingCoordinates == null;
    },

    rentBySector() {
      let out = {};

      this.locationRentalEstimate.forEach((r) => {
        out[r.sector] = {
          sector: r.sector,
          totalYearlyTopSquareMeterRent: r.topSqmRent,
          totalYearlyAvgSquareMeterRent: r.avgSqmRent,
          totalYearlyBottomSquareMeterRent: r.bottomSqmRent,
        };
      });

      return out;
    },

    referenceData() {
      let out = {};

      this.locationRentalEstimate.forEach((r) => {
        if (r.referenceData != null) {
          out[r.sector] = r.referenceData;
        }
      });

      return out;
    },

    sectorsWithNoResults() {
      if (!this.locationRentalEstimate) {
        return [];
      }

      return this.locationRentalEstimate.filter((r) => r.avgSqmRent == 0 && r.referenceData == null).map((r) => r.sector);
    },

    noResultsErrorText() {
      if (this.sectorsWithNoResults.length === 0) {
        return "";
      }

      const sectorsString = this.sectorsWithNoResults.map((s) => getTypeFromSector(s, this.$i18n)).join(", ");

      return this.$t("RENT_LEVELS_NEW_FILTERMENU_LOCATION_OR_FILTER_SPECIFIC", { sectors: sectorsString });
    },

    referenceNumberOfUnits: function () {
      return getReferenceNumberOfUnits(this.referenceData);
    },

    referenceTotalArea: function () {
      return getReferenceTotalArea(this.referenceData);
    },

    referenceDistanceToUnits: function () {
      return getReferenceDistanceToUnits(this.referenceData);
    },

    referenceConfidence: function () {
      return getReferenceConfidence(this.referenceData);
    },

    referenceMovingInYear: function () {
      return getReferenceMovingInYear(this.referenceData);
    },

    referenceConstructionYear: function () {
      return getReferenceConstructionYear(this.referenceData);
    },

    referenceResidentialConstructions: function () {
      return getReferenceResidentialConstructions(this.referenceData);
    },

    referenceSizeByType() {
      return getReferenceSizeByType(this.referenceData);
    },
  },

  methods: {
    filterMenuChange(value) {
      this.searchFilters = value;
    },

    download() {
      const dataForDownload = this.locationRentalEstimate.map((r) => {
        return {
          sectorName: getTypeFromSector(r.sector, this.$i18n),
          ...r,
        };
      });

      const locationRentalSheet = createLocationRentLevelsSheet(dataForDownload, "Rent levels");

      writeSheetsToXSLXAndSave([locationRentalSheet], "rental-levels");
    },
  },
};
