// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_Zyvuz{display:flex;flex-direction:column;justify-content:space-between;min-height:100%}.sticky_xjaGx{bottom:1rem;display:flex;flex-direction:row;margin:2rem auto 0;max-width:60%;position:sticky;z-index:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_Zyvuz",
	"sticky": "sticky_xjaGx"
};
module.exports = ___CSS_LOADER_EXPORT___;
